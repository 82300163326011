import { Environment } from './environment.model';

export const environment: Environment = {
	isLocal: false,
	isDevelopment: false,
	isStaging: true,
	isProduction: false,
	name: 'staging',
	suedtirolmobilUrl: {
		IT: 'https://test.altoadigemobilita.info/it/',
		DE: 'https://test.suedtirolmobil.info/de/',
		EN: 'https://test.suedtirolmobil.info/en/',
		LAD: 'https://test.suedtirolmobil.info/lad/'
	}
};
